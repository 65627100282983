import { observer } from "mobx-react";
import { BrowserRouter } from "react-router-dom";

import Guest from "./Guest";
import GuestRoutes from "../Routes/GuestRoutes";

const AppContent = () => {
  return (
    <BrowserRouter>
      <Guest>
        <GuestRoutes />
      </Guest>
    </BrowserRouter>
  );
};

export default observer(AppContent);
