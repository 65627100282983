import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const darkThemeColors = {};

const lightThemeColors = {
  primary: {
    main: "#168D8B", // Used in the sidebar, labels, etc
    medium: "#15616D",
  },
  greenAccent: {
    main: "#60C15C", // Used in buttons and labels
    medium: "#25A764", // Used in the Commercial Bin Icon Background Color
  },
  yellowAccent: {
    main: "#EFDB22", // Used in Map Page
  },
  orangeAccent: {
    main: "#FF7D00", // Used in Map Page and in validation errors
  },
  redAccent: {
    main: "#D00404", // Used in Map Page
  },
  brownAccent: {
    main: "#784949", // Used in Map Page
  },
  cyanAccent: {
    main: "#0094E7", // Used in Map Page
  },
  blueAccent: {
    light: "#BCD2E8", // Used in bg color of pages
    light2: "#91BAD6", // Used in the top bar bottom border
    medium: "#73A5C6", // Used for subtitle in the Forgot Password Page
    medium2: "#528AAE", // Used in icons in top-bar
    dark: "#2E5984", // Used for the labels in forms
    dark2: "#1E3F66",
  },
  greyAccent: {
    light: "#F9F9F9", // Used in bg color of pages
    light2: "#D1D5DA", // Used in the top bar bottom border
    medium: "#7B7C7C", // Used for subtitle in the Forgot Password Page
    medium2: "#6B7280", // Used in icons in top-bar
    dark: "#666666", // Used for the labels in forms
    dark2: "#333333", // Used for the main titles in forms
  },
  whiteAccent: {
    main: "#FFFFFF",
  },
  blackAccent: {
    main: "#000000",
  },
  bg: {
    whiteTransp: {
      "01": "rgba(255, 255, 255, 0.1)",
      "02": "rgba(255, 255, 255, 0.2)",
      "03": "rgba(255, 255, 255, 0.3)",
      "04": "rgba(255, 255, 255, 0.4)",
      "05": "rgba(255, 255, 255, 0.5)",
      "06": "rgba(255, 255, 255, 0.6)",
      "07": "rgba(255, 255, 255, 0.7)",
      "08": "rgba(255, 255, 255, 0.8)",
      "09": "rgba(255, 255, 255, 0.9)",
    },
  },
};

const darkThemeSettings = (colors) => {
  return {
    // palette values for dark mode
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.cyanAccent.main,
    },
    neutral: {
      dark: colors.greyAccent.dark,
      main: colors.greyAccent.medium,
      light: colors.greyAccent.light,
    },
    background: {
      default: colors.primary.main,
    },
  };
};

const lightThemeSettings = (colors) => {
  return {
    // palette values for light mode
    primary: {
      main: colors.blueAccent.light2,
    },
    secondary: {
      main: colors.greenAccent.main,
    },
    neutral: {
      dark: colors.blueAccent.dark,
      main: colors.blueAccent.medium,
      light: colors.blueAccent.light,
    },
    background: {
      default: colors.blueAccent.light,
    },
  };
};

// color design themeColors export
export const themeColors = (mode) => ({
  ...(mode === "dark" ? darkThemeColors : lightThemeColors),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = themeColors(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? darkThemeSettings(colors)
        : lightThemeSettings(colors)),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
