import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loadable from "../Components/Theme/Loadable";
import { lazy } from "react";

const DashboardPage = Loadable(lazy(() => import("../Pages/Guest/Dashboard")));
const ProjectDetails = Loadable(
  lazy(() => import("../Pages/Guest/ProjectDetails/ProjectDetails"))
);
const FinancialProgram = Loadable(
  lazy(() => import("../Pages/Guest/FinancialProgram/FinancialProgram"))
);

const FinancialProgramParent = Loadable(
  lazy(() => import("../Pages/Guest/FinancialProgram/FinancialProgramParent"))
);

const GuestRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route exact path="/" element={<DashboardPage />} />
      <Route
        exact
        path="/projectDetails/:projectId"
        element={<ProjectDetails />}
      />
      <Route
        exact
        path="/financialProgram/:financialProgramId"
        element={<FinancialProgram />}
      />

      <Route
        exact
        path="/financialProgramParent/:financialProgramParentId"
        element={<FinancialProgramParent />}
      />

      <Route
        path="*"
        element={<Navigate to="/" state={{ from: location }} />}
      />
    </Routes>
  );
};
export default GuestRoutes;
